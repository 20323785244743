import * as React from "react";

import Layout from "../components/layout";
import ServicesHero from "../components/servicesHero";
import ServicesText from "../components/ServicesText";
import ServicesColumns from "../components/ServicesColumns";
import ServicesMetrics from "../components/ServicesMetrics"
import ContactSection from "../components/ContactSection";

const Services = ({ pageContext }) => (
  <Layout>
    <ServicesHero pageContext={pageContext} />
    <ServicesText pageContext={pageContext} />
    <ServicesColumns pageContext={pageContext}/>
    <ServicesMetrics />
    <ContactSection/>
  </Layout>
);

export default Services;
