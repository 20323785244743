import * as React from "react";
import { useWindowSize } from "../hooks/useWindowSize";

const ServicesHero = ({ pageContext }) => {
  const windowSize = useWindowSize()
  return <div id="services"
  style={windowSize.width > 500 ?{
    height: 800,
  } : {height: 700}}
>
  <div
    className="relative h-full w-full"
  >
    <div className="absolute bottom-96 text-white w-full top-80">
      <div className="flex w-full justify-center">
        <div className="flex flex-col space-y-10">
          <div className="flex flex-col space-y-2">
            <div className="flex w-full justify-center text-gold-2 text-4xl uppercase tracking-wider text-center">
              <div> Carmen Lazar </div>
            </div>
            <div className="flex w-full justify-center">
              <div className="h-0.5 w-24 bg-gold my-auto"></div>
            </div>
          </div>
          <div className="flex flex-col space-y-6">
            <div className="flex justify-center text-5xl sm:text-8xl font-extrabold uppercase tracking-wider text-center">
              <div>{pageContext.title}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
};

export default ServicesHero;
