import * as React from "react";
import hammer from "../images/hammer.png"
import balance from "../images/balance.png"
import flowScheme from "../images/flow-scheme.png"
import apartmentBlocks from "../images/apartment-blocks.png"
import heart from "../images/heart.png"
import shield from "../images/shield.png"
import contract from "../images/contract.png"
import house from "../images/house.png"
import check from "../images/check.png"

const ServicesContainer = ({ title, text, img }) => {
  const images = {
    "hammer": hammer,
    "balance": balance,
    "flowScheme": flowScheme,
    "apartmentBlocks": apartmentBlocks,
    "heart": heart,
    "shield": shield,
    "contract": contract, 
    "house": house,
    "check": check
  }

  return <div className="flex flex-row rounded-md space-x-4 border border-gray-300 px-2 py-4 w-96">
    <div className="flex flex-col space-y-4 px-4">
      <div className="relative bottom-9">
        <div className="absolute w-full">
          <div className="flex w-full justify-center">
          <img
            alt="lawyer-icons"
            className="h-12 w-12 bg-white"
            src={images[img]}
          />
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <span className="flex w-full justify-center text-center text-2xl font-lg my-auto"><div className="w-64"> { title } </div> </span>
      </div>
      <div className="px-2">
        <div className="flex justify-center text-center font-light">
          <div> { text } </div>
        </div>
      </div>
    </div>
  </div>
};

export default ServicesContainer;
