import * as React from "react";

const ServicesText = ({ pageContext }) => (
  <div className="flex justify-center shadow-lg">
    <div className="absolute bg-top-bar top-80 my-64 md:my-80 lg:my-96 rounded-lg w-10/12 lg:w-auto">
    <div className="flex flex-col flex-nowrap bg-transparent text-white text-center space-y-8 px-10 sm:px-24 py-8 w-full">
      <div className="font-bold text-xl uppercase leading tracking-widest">Procedura & Consultanta</div>
      <p className="flex flex-col text-lg">
          <span className="text-md"> 
            In cadrul unei consultatii de specialitate, avocatul explica procedura si stabileste impreuna cu dumneavoastra 
          </span> 
          <span className="text-md"> strategia de aparare care va avantajeaza cel mai mult. </span>
      </p>
      <a href="#contact" className="px-4 py-4 bg-gold w-42 mx-auto rounded-lg tracking-widest uppercase text-xs">
        Stabilire Consultatie →
      </a>
    </div>
    </div>
  </div>
);

export default ServicesText;
