import * as React from "react"
import ServicesContainer from "./ServicesContainer"
import { useWindowSize } from "../hooks/useWindowSize"

const ServicesColumns = ({pageContext}) => {
    const windowSize = useWindowSize()

    return <div className={`flex justify-center bg-white ${windowSize.width < 900 ? "pt-96 sm:pt-32 md:pt-52 pb-16" : "pt-64 pb-32"}`}>
        <div className="flex flex-col space-y-12">
            <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-8 lg:space-x-12 px-10">
                <ServicesContainer 
                title={pageContext.containers[1].title} 
                img={pageContext.containers[1].img}
                text={pageContext.containers[1].text}  
                />
                <ServicesContainer 
                title={pageContext.containers[2].title} 
                img={pageContext.containers[2].img}
                text={pageContext.containers[2].text}  
                />
                <ServicesContainer 
                title={pageContext.containers[3].title} 
                img={pageContext.containers[3].img}
                text={pageContext.containers[3].text}  
                />
            </div>
            {pageContext.containers[4] ? <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-8 lg:space-x-12 px-10">
                <ServicesContainer  
                title={pageContext.containers[4].title} 
                img={pageContext.containers[4].img}
                text={pageContext.containers[4].text}  
                />
                <ServicesContainer 
                title={pageContext.containers[5].title} 
                img={pageContext.containers[5].img}
                text={pageContext.containers[5].text}  
                />
                <ServicesContainer 
                title={pageContext.containers[6].title} 
                img={pageContext.containers[6].img}
                text={pageContext.containers[6].text}  
                />
            </div> : ""}
        </div>
    </div>
}

export default ServicesColumns