import * as React from "react";

const ServicesMetrics = () => (
  <div className="flex justify-center bg-gold">
   <p className="text-white sm:px-4 py-10 leading-loose font-Merriweather w-8/12 sm:w-7/12 text-center text-xl sm:text-2xl">
   Inainte de a ne contacta, subliniem faptul ca in aceasta etapa sinceritatea informatiilor oferite de catre client este esentiala pentru o abordare cat mai corecta a cazului si stabilirea urmatorilor pasi.
   </p>
  </div>
);

export default ServicesMetrics;
